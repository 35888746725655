import React from "react"
import { FormattedMessage } from 'gatsby-plugin-intl';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1><FormattedMessage id="404title" /></h1>
    <p><FormattedMessage id="404text" /></p>
  </Layout>
)

export default NotFoundPage
